@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:opsz,wght@6..12,400;6..12,500;6..12,600;6..12,700;6..12,800&display=swap");

* {
  padding: 0;
  margin: 0;
}

body {
  font-family: "Nunito Sans", sans-serif;
}
/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #fff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #087c7c;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #087c7c;
}

.signup-bg,
#banner-section {
  background-image: url("../src/assets/gif-background.gif");
  background-size: cover;
}

.carousel-bg {
  background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.28) 0%,
      rgba(0, 0, 0, 0.28) 100%
    ),
    url("../src/assets/carousel-bg.png"),
    lightgray 0px -6.534px / 100% 134.356% no-repeat;
}

#main-header {
  position: fixed;
  width: 100%;
  top: 0;
}

#main-header + section {
  margin-top: 92px;
}

.custom-arrow {
  z-index: 1;
  position: absolute;
  bottom: 0;
  top: unset;
  margin-bottom: 45px;
  right: 0;
}

.next-arrow {
  margin-right: 45px;
}

.prev-arrow {
  margin-right: 149px;
}

@media (min-width: 1024px) {
  #main-header + section {
    margin-top: 80px;
  }
}

@media (max-width: 768px) {
  .contact-us-box {
    background: #161e2e !important;
  }
}

@media screen and (max-height: 700px) {
  .admin-logo-div {
    margin-bottom: 50px;
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.vertical-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.vertical-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99999;
  background: radial-gradient(rgba(20, 20, 20, 0.8), rgba(0, 0, 0, 0.6));
}

.loaderImage-container {
  position: absolute !important;
  left: 50% !important;
  top: 50% !important;
  transform: translate(-50%, -50%);
}

.pagination ul li {
  border-radius: 24px;
  display: flex;
  width: 46.5px;
  padding: 10.25px 0px 11.25px 0px;
  justify-content: center;
  align-items: center;
  color: #161e2e;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.pagination ul li.selected {
  color: #f9f9f9;
  background: #186cce;
}

.rmsc {
  --rmsc-main: #e9ebed !important;
  /* --rmsc-hover: #0e0c0a !important;
  --rmsc-selected: #1d1915 !important; */
  --rmsc-border: #e9ebed !important;
  /* --rmsc-gray: #555555 !important; */
  --rmsc-bg: #e9ebed !important;
  color: #161e2e !important;
  --rmsc-p: 10px !important; /* Spacing */
  --rmsc-radius: 5px !important; /* Radius */
  --rmsc-h: 38px !important; /* Height */
}
input[type="checkbox"] {
  accent-color: #186cce;
}

.dropdown-content input {
  color: #161e2e;
}
.rmsc .dropdown-container {
  border: unset;
  background-color: unset;
}
.rmsc .dropdown-container:focus-within {
  box-shadow: unset;
}
svg.dropdown-heading-dropdown-arrow.gray {
  fill: #161e2e;
  color: #161e2e;
  width: 20px;
}
.rmsc .dropdown-heading {
  padding: 0 !important;
}
.rmsc .dropdown-content ul {
  max-height: 180px !important;
  overflow: auto !important;
}
.rmsc .dropdown-heading .dropdown-heading-value {
  font-weight: 600;
}
/* .tooltip-parent{
  position: relative;
} */
/* .tooltip-parent .tooltip{
 display: none;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
} */
/* .tooltip-parent:hover .tooltip{
  display: block;
} */
.success-message {
  position: fixed;
  bottom: 20px; /* Adjust the distance from the bottom as needed */
  left: 50%;
  transform: translateX(-50%);
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

.react-dropdown-tree-select .dropdown .dropdown-content {
  background: #f9f9f9 !important;
  box-shadow: unset !important;
  border: unset !important;
  position: unset !important;
}

/* Add this to your CSS file */
.order-pic {
  transition: filter 0.3s ease;
}

.dashboard-card:hover .order-pic {
  filter: brightness(0) invert(1);
}

.orderIcon {
  transition: filter 0.3s ease;
  filter: brightness(0) invert(1);
}


